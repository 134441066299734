import * as React from "react"
import { FC } from "react"
import LPBannerTabsBlock from "../components/banner/LPBannerTabsBlock"
import LPProductCarouselFullWidthBlock from "../components/products/LPProductCarouselFullWidthBlock"

interface Props {}

const HomePageLP: FC<Props> = () => {
  return (
    <div>
      <LPBannerTabsBlock />
      <LPProductCarouselFullWidthBlock
        collectionName={"Hamper Landing Page"}
        showImpactArrow={false}
        hideBrand
        large
        exVat
        noLink
        ignoreProductVisibility
      />
    </div>
  )
}

export default HomePageLP
