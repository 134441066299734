import SEO from "../components/Seo"
import * as React from "react"
import Layout from "../components/Layout"
import HomePageLP from "../social-supermarket/components/landing-page/new/pages/HomePageLP"
import { FC } from "react"

interface Props {}

const HomePage: FC<Props> = () => {
  return (
    <Layout fullWidth>
      <SEO title="Social Supermarket" description="" />
      <HomePageLP />
    </Layout>
  )
}

export default HomePage
